var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticStyle: { padding: "40px" } },
    [
      _c("h2", [
        _c(
          "span",
          {
            attrs: {
              id: "metrics-page-heading",
              "data-cy": "metricsPageHeading",
            },
          },
          [_vm._v("Application Metrics")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary float-right",
            on: {
              click: function ($event) {
                return _vm.refresh()
              },
            },
          },
          [
            _c("font-awesome-icon", { attrs: { icon: "sync" } }),
            _vm._v(" "),
            _c("span", [_vm._v("Refresh")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("h3", [_vm._v("JVM Metrics")]),
      _vm._v(" "),
      !_vm.updatingMetrics
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c("h4", [_vm._v("Memory")]),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(_vm.metrics.jvm, function (entry, key) {
                  return _c(
                    "div",
                    { key: key },
                    [
                      entry.max !== -1
                        ? _c("span", [
                            _c("span", [_vm._v(_vm._s(key))]),
                            _vm._v(
                              " (" +
                                _vm._s(
                                  _vm.formatNumber1(entry.used / 1048576)
                                ) +
                                "M /\n            " +
                                _vm._s(_vm.formatNumber1(entry.max / 1048576)) +
                                "M)\n          "
                            ),
                          ])
                        : _c("span", [
                            _c("span", [_vm._v(_vm._s(key))]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatNumber1(entry.used / 1048576)
                                ) +
                                "M\n          "
                            ),
                          ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "Committed : " +
                            _vm._s(
                              _vm.formatNumber1(entry.committed / 1048576)
                            ) +
                            "M"
                        ),
                      ]),
                      _vm._v(" "),
                      entry.max !== -1
                        ? _c(
                            "b-progress",
                            {
                              attrs: {
                                variant: "success",
                                animated: "",
                                max: entry.max,
                                striped: "",
                              },
                            },
                            [
                              _c("b-progress-bar", {
                                attrs: {
                                  value: entry.used,
                                  label:
                                    _vm.formatNumber1(
                                      (entry.used * 100) / entry.max
                                    ) + "%",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("h4", [_vm._v("Threads")]),
                _vm._v(" "),
                _c("span", [
                  _c("span", [_vm._v("Runnable")]),
                  _vm._v(" " + _vm._s(_vm.threadStats.threadDumpRunnable)),
                ]),
                _vm._v(" "),
                _c(
                  "b-progress",
                  {
                    attrs: {
                      variant: "success",
                      max: _vm.threadStats.threadDumpAll,
                      striped: "",
                    },
                  },
                  [
                    _c("b-progress-bar", {
                      attrs: {
                        value: _vm.threadStats.threadDumpRunnable,
                        label:
                          _vm.formatNumber1(
                            (_vm.threadStats.threadDumpRunnable * 100) /
                              _vm.threadStats.threadDumpAll
                          ) + "%",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _c("span", [_vm._v("Timed Waiting")]),
                  _vm._v(
                    " (" + _vm._s(_vm.threadStats.threadDumpTimedWaiting) + ")"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "b-progress",
                  {
                    attrs: {
                      variant: "success",
                      max: _vm.threadStats.threadDumpAll,
                      striped: "",
                    },
                  },
                  [
                    _c("b-progress-bar", {
                      attrs: {
                        value: _vm.threadStats.threadDumpTimedWaiting,
                        label:
                          _vm.formatNumber1(
                            (_vm.threadStats.threadDumpTimedWaiting * 100) /
                              _vm.threadStats.threadDumpAll
                          ) + "%",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _c("span", [_vm._v("Waiting")]),
                  _vm._v(
                    " (" + _vm._s(_vm.threadStats.threadDumpWaiting) + ")"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "b-progress",
                  {
                    attrs: {
                      variant: "success",
                      max: _vm.threadStats.threadDumpAll,
                      striped: "",
                    },
                  },
                  [
                    _c("b-progress-bar", {
                      attrs: {
                        value: _vm.threadStats.threadDumpWaiting,
                        label:
                          _vm.formatNumber1(
                            (_vm.threadStats.threadDumpWaiting * 100) /
                              _vm.threadStats.threadDumpAll
                          ) + "%",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _c("span", [_vm._v("Blocked")]),
                  _vm._v(
                    " (" + _vm._s(_vm.threadStats.threadDumpBlocked) + ")"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "b-progress",
                  {
                    attrs: {
                      variant: "success",
                      max: _vm.threadStats.threadDumpAll,
                      striped: "",
                    },
                  },
                  [
                    _c("b-progress-bar", {
                      attrs: {
                        value: _vm.threadStats.threadDumpBlocked,
                        label:
                          _vm.formatNumber1(
                            (_vm.threadStats.threadDumpBlocked * 100) /
                              _vm.threadStats.threadDumpAll
                          ) + "%",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    "Total: " +
                      _vm._s(_vm.threadStats.threadDumpAll) +
                      "\n        "
                  ),
                  _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.metricsModal",
                          modifiers: { metricsModal: true },
                        },
                      ],
                      staticClass: "hand",
                      attrs: {
                        "data-toggle": "modal",
                        "data-target": "#threadDump",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openModal()
                        },
                      },
                    },
                    [_c("font-awesome-icon", { attrs: { icon: "eye" } })],
                    1
                  ),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-4" },
              [
                _c("h4", [_vm._v("System")]),
                _vm._v(" "),
                !_vm.updatingMetrics
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-4" }, [
                        _vm._v("Uptime"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-8 text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.convertMillisecondsToDuration(
                                _vm.metrics.processMetrics["process.uptime"]
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.updatingMetrics
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-4" }, [
                        _vm._v("Start time"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-8 text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("formatMillis")(
                                _vm.metrics.processMetrics["process.start.time"]
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.updatingMetrics
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-9" }, [
                        _vm._v("Process CPU usage"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-3 text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber2(
                                100 *
                                  _vm.metrics.processMetrics[
                                    "process.cpu.usage"
                                  ]
                              )
                            ) +
                            " %\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-progress",
                  { attrs: { variant: "success", max: 100, striped: "" } },
                  [
                    _c("b-progress-bar", {
                      attrs: {
                        value:
                          100 * _vm.metrics.processMetrics["process.cpu.usage"],
                        label:
                          _vm.formatNumber1(
                            100 *
                              _vm.metrics.processMetrics["process.cpu.usage"]
                          ) + "%",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.updatingMetrics
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-9" }, [
                        _vm._v("System CPU usage"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-3 text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber2(
                                100 *
                                  _vm.metrics.processMetrics["system.cpu.usage"]
                              )
                            ) +
                            " %\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-progress",
                  { attrs: { variant: "success", max: 100, striped: "" } },
                  [
                    _c("b-progress-bar", {
                      attrs: {
                        value:
                          100 * _vm.metrics.processMetrics["system.cpu.usage"],
                        label:
                          _vm.formatNumber1(
                            100 * _vm.metrics.processMetrics["system.cpu.usage"]
                          ) + "%",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.updatingMetrics
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-9" }, [
                        _vm._v("System CPU count"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-3 text-right" }, [
                        _vm._v(
                          _vm._s(_vm.metrics.processMetrics["system.cpu.count"])
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.updatingMetrics
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-9" }, [
                        _vm._v("System 1m Load average"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-3 text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber2(
                                _vm.metrics.processMetrics[
                                  "system.load.average.1m"
                                ]
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.updatingMetrics
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-9" }, [
                        _vm._v("Process files max"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-3 text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber1(
                                _vm.metrics.processMetrics["process.files.max"]
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.updatingMetrics
                  ? _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-9" }, [
                        _vm._v("Process files open"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-md-3 text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber1(
                                _vm.metrics.processMetrics["process.files.open"]
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h3", [_vm._v("Garbage collections")]),
      _vm._v(" "),
      !_vm.updatingMetrics &&
      _vm.isObjectExisting(_vm.metrics, "garbageCollector")
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                [
                  _c("span", [
                    _vm._v(
                      "\n          GC Live Data Size/GC Max Data Size (" +
                        _vm._s(
                          _vm.formatNumber1(
                            _vm.metrics.garbageCollector[
                              "jvm.gc.live.data.size"
                            ] / 1048576
                          )
                        ) +
                        "M / " +
                        _vm._s(
                          _vm.formatNumber1(
                            _vm.metrics.garbageCollector[
                              "jvm.gc.max.data.size"
                            ] / 1048576
                          )
                        ) +
                        "M)\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-progress",
                    {
                      attrs: {
                        variant: "success",
                        max: _vm.metrics.garbageCollector[
                          "jvm.gc.max.data.size"
                        ],
                        striped: "",
                      },
                    },
                    [
                      _c("b-progress-bar", {
                        attrs: {
                          value:
                            _vm.metrics.garbageCollector[
                              "jvm.gc.live.data.size"
                            ],
                          label:
                            _vm.formatNumber2(
                              (100 *
                                _vm.metrics.garbageCollector[
                                  "jvm.gc.live.data.size"
                                ]) /
                                _vm.metrics.garbageCollector[
                                  "jvm.gc.max.data.size"
                                ]
                            ) + "%",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                [
                  _c("span", [
                    _vm._v(
                      "\n          GC Memory Promoted/GC Memory Allocated (" +
                        _vm._s(
                          _vm.formatNumber1(
                            _vm.metrics.garbageCollector[
                              "jvm.gc.memory.promoted"
                            ] / 1048576
                          )
                        ) +
                        "M /\n          " +
                        _vm._s(
                          _vm.formatNumber1(
                            _vm.metrics.garbageCollector[
                              "jvm.gc.memory.allocated"
                            ] / 1048576
                          )
                        ) +
                        "M)\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-progress",
                    {
                      attrs: {
                        variant: "success",
                        max: _vm.metrics.garbageCollector[
                          "jvm.gc.memory.allocated"
                        ],
                        striped: "",
                      },
                    },
                    [
                      _c("b-progress-bar", {
                        attrs: {
                          value:
                            _vm.metrics.garbageCollector[
                              "jvm.gc.memory.promoted"
                            ],
                          label:
                            _vm.formatNumber2(
                              (100 *
                                _vm.metrics.garbageCollector[
                                  "jvm.gc.memory.promoted"
                                ]) /
                                _vm.metrics.garbageCollector[
                                  "jvm.gc.memory.allocated"
                                ]
                            ) + "%",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-md-4" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _vm._v("Classes loaded"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3 text-right" }, [
                  _vm._v(_vm._s(_vm.metrics.garbageCollector.classesLoaded)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-9" }, [
                  _vm._v("Classes unloaded"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-md-3 text-right" }, [
                  _vm._v(_vm._s(_vm.metrics.garbageCollector.classesUnloaded)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "table-responsive" }, [
              _c(
                "table",
                {
                  staticClass: "table table-striped",
                  attrs: { "aria-describedby": "Jvm gc" },
                },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [_vm._v("jvm.gc.pause")]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          _vm._s(
                            _vm.metrics.garbageCollector["jvm.gc.pause"].count
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.formatNumber2(
                                _vm.metrics.garbageCollector["jvm.gc.pause"]
                                  .mean
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.formatNumber2(
                                _vm.metrics.garbageCollector["jvm.gc.pause"][
                                  "0.0"
                                ]
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.formatNumber2(
                                _vm.metrics.garbageCollector["jvm.gc.pause"][
                                  "0.5"
                                ]
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.formatNumber2(
                                _vm.metrics.garbageCollector["jvm.gc.pause"][
                                  "0.75"
                                ]
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.formatNumber2(
                                _vm.metrics.garbageCollector["jvm.gc.pause"][
                                  "0.95"
                                ]
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.formatNumber2(
                                _vm.metrics.garbageCollector["jvm.gc.pause"][
                                  "0.99"
                                ]
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm.formatNumber2(
                                _vm.metrics.garbageCollector["jvm.gc.pause"].max
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h3", [_vm._v("HTTP requests (time in millisecond)")]),
      _vm._v(" "),
      !_vm.updatingMetrics &&
      _vm.isObjectExisting(_vm.metrics, "http.server.requests")
        ? _c(
            "table",
            {
              staticClass: "table table-striped",
              attrs: { "aria-describedby": "Jvm http" },
            },
            [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(
                  _vm.metrics["http.server.requests"]["percode"],
                  function (entry, key) {
                    return _c("tr", { key: key }, [
                      _c("td", [_vm._v(_vm._s(key))]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c(
                            "b-progress",
                            {
                              attrs: {
                                variant: "success",
                                animated: "",
                                max: _vm.metrics["http.server.requests"]["all"]
                                  .count,
                                striped: "",
                              },
                            },
                            [
                              _c("b-progress-bar", {
                                attrs: {
                                  value: entry.count,
                                  label: _vm.formatNumber1(entry.count),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber2(_vm.filterNaN(entry.mean))
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(_vm.formatNumber2(entry.max))),
                      ]),
                    ])
                  }
                ),
                0
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("h3", [_vm._v("Endpoints requests (time in millisecond)")]),
      _vm._v(" "),
      !_vm.updatingMetrics
        ? _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              {
                staticClass: "table table-striped",
                attrs: { "aria-describedby": "Endpoint" },
              },
              [
                _vm._m(2),
                _vm._v(" "),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.metrics.services, function (entry, entryKey) {
                      return _vm._l(entry, function (method, methodKey) {
                        return _c("tr", { key: entryKey + "-" + methodKey }, [
                          _c("td", [_vm._v(_vm._s(methodKey))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(entryKey))]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(_vm._s(method.count)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(_vm._s(_vm.formatNumber2(method.mean))),
                          ]),
                        ])
                      })
                    }),
                  ],
                  2
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h3", [_vm._v("Cache statistics")]),
      _vm._v(" "),
      !_vm.updatingMetrics && _vm.isObjectExisting(_vm.metrics, "cache")
        ? _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              {
                staticClass: "table table-striped",
                attrs: { "aria-describedby": "Cache" },
              },
              [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.metrics.cache, function (entry, key) {
                    return _c("tr", { key: key }, [
                      _c("td", [_vm._v(_vm._s(key))]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(entry["cache.gets.hit"])),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(entry["cache.gets.miss"])),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          _vm._s(
                            entry["cache.gets.hit"] + entry["cache.gets.miss"]
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(entry["cache.puts"])),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(entry["cache.removals"])),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(_vm._s(entry["cache.evictions"])),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.formatNumber2(
                                _vm.filterNaN(
                                  (100 * entry["cache.gets.hit"]) /
                                    (entry["cache.gets.hit"] +
                                      entry["cache.gets.miss"])
                                )
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.formatNumber2(
                                _vm.filterNaN(
                                  (100 * entry["cache.gets.miss"]) /
                                    (entry["cache.gets.hit"] +
                                      entry["cache.gets.miss"])
                                )
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("h3", [_vm._v("DataSource statistics (time in millisecond)")]),
      _vm._v(" "),
      !_vm.updatingMetrics &&
      _vm.isObjectExistingAndNotEmpty(_vm.metrics, "databases")
        ? _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              {
                staticClass: "table table-striped",
                attrs: { "aria-describedby": "Connection pool" },
              },
              [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { scope: "col" } }, [
                      _c("span", [_vm._v("Connection Pool Usage")]),
                      _vm._v(
                        " (active: " +
                          _vm._s(_vm.metrics.databases.active.value) +
                          ", min:\n            " +
                          _vm._s(_vm.metrics.databases.min.value) +
                          ", max: " +
                          _vm._s(_vm.metrics.databases.max.value) +
                          ", idle:\n            " +
                          _vm._s(_vm.metrics.databases.idle.value) +
                          ")\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticClass: "text-right", attrs: { scope: "col" } },
                      [_vm._v("Count")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticClass: "text-right", attrs: { scope: "col" } },
                      [_vm._v("Mean")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticClass: "text-right", attrs: { scope: "col" } },
                      [_vm._v("Min")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticClass: "text-right", attrs: { scope: "col" } },
                      [_vm._v("p50")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticClass: "text-right", attrs: { scope: "col" } },
                      [_vm._v("p75")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticClass: "text-right", attrs: { scope: "col" } },
                      [_vm._v("p95")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticClass: "text-right", attrs: { scope: "col" } },
                      [_vm._v("p99")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticClass: "text-right", attrs: { scope: "col" } },
                      [_vm._v("Max")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [_vm._v("Acquire")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.metrics.databases.acquire.count)),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.formatNumber2(
                              _vm.filterNaN(_vm.metrics.databases.acquire.mean)
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.metrics.databases.acquire["0.0"]
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.metrics.databases.acquire["0.5"]
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.metrics.databases.acquire["0.75"]
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.metrics.databases.acquire["0.95"]
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.metrics.databases.acquire["0.99"]
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.formatNumber2(
                              _vm.filterNaN(_vm.metrics.databases.acquire.max)
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("Creation")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.metrics.databases.creation.count)),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.formatNumber2(
                              _vm.filterNaN(_vm.metrics.databases.creation.mean)
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.metrics.databases.creation["0.0"]
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.metrics.databases.creation["0.5"]
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.metrics.databases.creation["0.75"]
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.metrics.databases.creation["0.95"]
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.metrics.databases.creation["0.99"]
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.formatNumber2(
                              _vm.filterNaN(_vm.metrics.databases.creation.max)
                            )
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("Usage")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.metrics.databases.usage.count)),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.filterNaN(_vm.metrics.databases.usage.mean)
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(_vm.metrics.databases.usage["0.0"])
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(_vm.metrics.databases.usage["0.5"])
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(_vm.metrics.databases.usage["0.75"])
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(_vm.metrics.databases.usage["0.95"])
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(_vm.metrics.databases.usage["0.99"])
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatNumber2(
                            _vm.filterNaN(_vm.metrics.databases.usage.max)
                          )
                        )
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        { ref: "metricsModal", attrs: { size: "lg" } },
        [
          _c(
            "h4",
            {
              staticClass: "modal-title",
              attrs: { slot: "modal-title", id: "showMetricsLabel" },
              slot: "modal-title",
            },
            [_vm._v("Threads dump")]
          ),
          _vm._v(" "),
          _c("metrics-modal", { attrs: { "thread-dump": _vm.threadData } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Count"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Mean"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Min"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("p50"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("p75"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("p95"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("p99"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Max"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Code")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Count")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Mean"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Max"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Method")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Endpoint url")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Count"),
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right", attrs: { scope: "col" } }, [
          _vm._v("Mean"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Cache name")]),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-right",
            attrs: { scope: "col", "data-translate": "metrics.cache.hits" },
          },
          [_vm._v("Cache Hits")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-right",
            attrs: { scope: "col", "data-translate": "metrics.cache.misses" },
          },
          [_vm._v("\n            Cache Misses\n          ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-right",
            attrs: { scope: "col", "data-translate": "metrics.cache.gets" },
          },
          [_vm._v("Cache Gets")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-right",
            attrs: { scope: "col", "data-translate": "metrics.cache.puts" },
          },
          [_vm._v("Cache Puts")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-right",
            attrs: { scope: "col", "data-translate": "metrics.cache.removals" },
          },
          [_vm._v("\n            Cache Removals\n          ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-right",
            attrs: {
              scope: "col",
              "data-translate": "metrics.cache.evictions",
            },
          },
          [_vm._v("\n            Cache Evictions\n          ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-right",
            attrs: {
              scope: "col",
              "data-translate": "metrics.cache.hitPercent",
            },
          },
          [_vm._v("\n            Cache Hit %\n          ")]
        ),
        _vm._v(" "),
        _c(
          "th",
          {
            staticClass: "text-right",
            attrs: {
              scope: "col",
              "data-translate": "metrics.cache.missPercent",
            },
          },
          [_vm._v("\n            Cache Miss %\n          ")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }